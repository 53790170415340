import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";
import path from "path";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngPosDevelopment = loadable(() => import("../components/PosDevelopment/english.js"));
const ArabicPosDevelopment = loadable(() => import("../components/PosDevelopment/arabic.js"));
const ChinesePosDevelopment = loadable(() => import("../components/PosDevelopment/chinese.js"));

const schema = {
    "@context": "http://schema.org/",
    "@graph": [
        {
            "@type": "product",
            "image": "https://softtik.com/images/nft-collection/invest-section-19.webp",
            "name": "POS Blockchain Development Company",
            "description": "Softtik is the top POS blockchain development company with the right expertise and experience to provide fine-quality services to its clients.",
            "brand": "Softtik Technologies",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.7",
                "reviewCount": "104"
            }
        },
        {
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "What is Proof of Stake?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Proof-of-stake is a cryptocurrency consensus mechanism that processes transactions and adds new blocks to a blockchain. A consensus mechanism is a method for validating and securing entries in a distributed database.</p>"
                }
            }, {
                "@type": "Question",
                "name": "Who creates blocks in proof-of-stake?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>The algorithm selects random people to validate the blocks' transactions, and the lucky person has to stake his coins. <b>The person validating transactions creates new blocks on the blockchain</b>.</p>"
                }
            }, {
                "@type": "Question",
                "name": "Is proof-of-stake profitable?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Yes, The Proof of Stake mechanism is more profitable than the Proof of Work due to its less resource-consumption nature. Investors can enjoy staking rewards without any mining rigs.</p>"
                }
            }
            ]
        }
    ]
}

export class PosDevelopment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English",
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        // else if (region == 'sa') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path='/pos-blockchain-development-services/'
                        title={`POS Blockchain Development Company - Softtik Technologies`}
                        thumbnail="https://softtik.com/images/nft-collection/invest-section-19.webp"
                        description={`Softtik is the top POS blockchain development company with the right expertise and experience to provide fine-quality services to its clients.`}
                    />
                    <main>
                        <div id="wrapper">
                            <div className={`collection-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicPosDevelopment />
                                    : lang == "China"
                                        ? <ChinesePosDevelopment />
                                        : <EngPosDevelopment />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default PosDevelopment;